import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Dummy from '../../images/bars.svg';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function UsersThisWeek() {
  const [thisWeekUsers, setThisWeekUsers] = useState([]);

    useEffect(() => {
        axios.get(prod + '/user/thisweek')
        .then((response) => {
          setThisWeekUsers(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='card card-user'>
      <div className='card-bar'>
       <img className="dummy" src={Dummy} alt='bars'/>
      </div>
      <div className='card-text'>
      <h4>Last 7 Days</h4>
        {
          thisWeekUsers.map((item) => (
            <div key={item.id} >
              <h5>{item.user_count}</h5>
            </div>
         )) 
        }
      </div>
    </div>
  )
}

export default UsersThisWeek