// Modal.js
import React from 'react';
import './ModalTwo.css'; // Import CSS for modal styles
import TodayNewsClicks from './TodayNewsClicks';

const ModalThree = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button  className="close-button" onClick={onClose}>Close</button>
            <TodayNewsClicks/>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalThree;
