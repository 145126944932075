// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-page{
    display: flex;
    flex-direction: row;
    height: 100vh;
    margin: 0;
    padding: 0;
}
.sidenav{
    width: 25%;
}
.page-content{
    width: 75%;
    display: flex;
    flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/pages/Common.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".common-page{\n    display: flex;\n    flex-direction: row;\n    height: 100vh;\n    margin: 0;\n    padding: 0;\n}\n.sidenav{\n    width: 25%;\n}\n.page-content{\n    width: 75%;\n    display: flex;\n    flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
