import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import ModalTwo from './ModalTwo';
import {FaPlus} from 'react-icons/fa'
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function Active() {
  const [activeNews, setActiveNews] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

    useEffect(() => {
        axios.get(prod + '/news/active')
        .then((response) => {
          setActiveNews(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='news-card'>
      <div>
          Active
      </div>
      <div className='news-card-content'>
        <div>
        {
          activeNews.map((item) => (
            <div key={item.id} >
              <h5>{item.activenews}</h5>
            </div>
         )) 
        }
        </div>
        <div>
        <FaPlus className='more' onClick={openModal}/>
        </div>
      </div>
      
      <ModalTwo isOpen={modalOpen} onClose={closeModal} />
    </div>
  )
}

export default Active