import React from 'react';
import ActiveNewsPerPublisher from './ActiveNewsPerPublisher';
import './ModalTwo.css'


const Modal = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={onClose}>Close</button>
            <ActiveNewsPerPublisher/>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
