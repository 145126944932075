import React from 'react';
import PublishersByStatus from './PublishersByStatus';
import './ModalTwo.css'


const ModalFour = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={onClose}>Close</button>
            <PublishersByStatus/>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalFour;
