import './App.css';
// import AdClicks from './pages/AdClicks.js';
// import NativeAds from './pages/NativeAds.js';
import Login from './pages/Login.js';
import Users from './pages/Users.js';
import News from './pages/News.js';
import Reports from './pages/Reports.js'
import Sidebar from './components/Sidebar.js';
import UserNewsActivity from './pages/UserNewsActivity.js';
import UserAppActivity from './pages/UserAppActivity.js';
import ShopZetu from './pages/ShopZetu.js';
import Merchants from './pages/Merchants.js';
import Pepinos from './pages/Pepinos.js';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div className='App'>
  <Router>
    <div className='app-left'>
     <Sidebar/>
    </div>
    <div className='app-right'>
    <Routes>
      <Route exact path="/" element={<Login/>}/>
      <Route exact path="/user" element={<Users/>}/>
      <Route exact path="/news" element={<News/>}/>
      <Route exact path="/usernewsactivity" element={<UserNewsActivity/>}/>
      <Route exact path="/userappactivity" element={<UserAppActivity/>}/>
      <Route exact path="/reports" element={<Reports/>}/>
      <Route exact path="/shopzetu" element={<ShopZetu/>}/>
      <Route exact path="/merchants" element={<Merchants/>}/>
      <Route exact path="/pepinos" element={<Pepinos/>}/>
    {/* <Route exact path="/adclicks" element={<AdClicks/>}/> */}
    </Routes> 
    </div>
 </Router>

 </div> 
  );
}

export default App;
