import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';

const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function ActiveManual() {
    const [activeNews, setActiveNews] = useState([]);

    useEffect(() => {
        axios.get(prod + '/news/manual')
        .then((response) => {
          setActiveNews(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='news-card'>ActiveManual
        {
          activeNews.map((item) => (
            <div key={item.id} >
              <h5>{item.activemanualnews}</h5>
            </div>
         )) 
        }
    </div>
  )
}

export default ActiveManual