// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-card{
    width: 49%;
    height: 56vh;
    display: flex;
    flex-direction: column;
}

.reports{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid rgba(255,228,196, 1);
    margin-bottom: 20px;
}

.bg2{
    background-color: rgba(41, 40, 145, 0.1)
}
.report-row{
    display: flex;
    flex-direction: row;
}
.report-element{
    padding: 5px;
}
.report-head{
    margin-top: 10px;
    margin-bottom: 10px;
}

.ch{
    height: 120px;
    width: 32%;
    padding: 5px;
}

.company-info{
    display: flex;
    flex-direction: row;
    background-color: rgba(255,228,196,0.7);
    border-radius: 10px;
    
}
.ad{
    margin-top: 20px;
}

.c-heading{
    color: rgba(65, 92, 217, 1);
}
.pd{
    padding: 2px 5px;
}
.desc{
    font-weight: bold;
}
.sp{
    color:rgba(255, 99, 132, 1);
    font-weight: bolder;
}
.sp2{
    color:rgba(255, 99, 132, 1);
    font-weight: normal;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Reports.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI;AACJ;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uCAAuC;IACvC,mBAAmB;;AAEvB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,2BAA2B;IAC3B,mBAAmB;AACvB","sourcesContent":[".report-card{\n    width: 49%;\n    height: 56vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.reports{\n    display: flex;\n    flex-direction: column;\n    border-radius: 10px;\n    border: 1px solid rgba(255,228,196, 1);\n    margin-bottom: 20px;\n}\n\n.bg2{\n    background-color: rgba(41, 40, 145, 0.1)\n}\n.report-row{\n    display: flex;\n    flex-direction: row;\n}\n.report-element{\n    padding: 5px;\n}\n.report-head{\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.ch{\n    height: 120px;\n    width: 32%;\n    padding: 5px;\n}\n\n.company-info{\n    display: flex;\n    flex-direction: row;\n    background-color: rgba(255,228,196,0.7);\n    border-radius: 10px;\n    \n}\n.ad{\n    margin-top: 20px;\n}\n\n.c-heading{\n    color: rgba(65, 92, 217, 1);\n}\n.pd{\n    padding: 2px 5px;\n}\n.desc{\n    font-weight: bold;\n}\n.sp{\n    color:rgba(255, 99, 132, 1);\n    font-weight: bolder;\n}\n.sp2{\n    color:rgba(255, 99, 132, 1);\n    font-weight: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
