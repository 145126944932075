import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import './ModalTwo.css';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function ActiveNewsClicks() {
    const [activeNewsClicks, setActiveNewsClicks] = useState([]);
    useEffect(() => {
        axios.get(prod + '/news/activenewsclicks')
        .then((response) => {
          setActiveNewsClicks(response.data);
          
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='clicks-table'>
      <div className='c_table bolder'>
        <span className='table-item-1'>Publisher ID</span>
        <span className='table-item-2'>Article ID</span>
        <span className='table-item-3'>Headline</span>
        <span className='table-item-1'>Clicks</span>
        <span className='table-item-2'>Publisher</span>
    </div>
        {
          activeNewsClicks.map((item) => (
            <div key={item.id} className='c_table bold'>
               <span className='table-item-1'>{item.provider_no}</span>
               <span className='table-item-2'>{item.id}</span>
               <span className='table-item-3'>{item.head_line}</span>
               <span className='table-item-1'> {item.news_click}</span>
               <span className='table-item-2'> {item.provider_name}</span>
            </div>
         )) 
        }
    
       
    </div>
  )
}

export default ActiveNewsClicks