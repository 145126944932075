import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function ByValue() {
    const [allValues, setAllValues] = useState([]);

    useEffect(() => {
        axios.get(prod + '/merchants/byvalue')
        .then((response) => {
          setAllValues(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
      
  return (
    <div className='days-cards top-20 value-card'>
        {
          allValues.map((item) => (
            <div className='value-item' key={item.id} >
              <h5 >Voucher Value  {item.value}   Redeemed Vouchers  <span className='v-num'>{item.VoucherCount}</span></h5>
            </div>
         )) 
        }
    </div>
  )
}

export default ByValue