import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Dummy from '../../images/bars.svg';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function Total() {
    const [allNews, setAllNews] = useState([]);

    useEffect(() => {
        axios.get(prod + '/news/total')
        .then((response) => {
          setAllNews(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
      
  return (
    <div className='news-card'>Total
        {
          allNews.map((item) => (
            <div key={item.id} >
              <h5>{item.allnews}</h5>
            </div>
         )) 
        }
    </div>
  )
}

export default Total