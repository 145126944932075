import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function ActiveNewsPerPublisher() {
    const [activeNewsPerPublisher, setActiveNewsPerPublisher] = useState([]);
    useEffect(() => {
        axios.get(prod + '/news/activecountperpublisher')
        .then((response) => {
          setActiveNewsPerPublisher(response.data);
          
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='pub-table'>
      <div className='table'>
        <span className='table-item'>Logo</span>
        <span className='table-item'>Publisher ID</span>
        <span className='table-item'>Publisher Name</span>
        <span className='table-item'>Active Articles</span>
    </div>
        {
          activeNewsPerPublisher.map((item) => (
            <div key={item.id} className='table'>
               <span className='table-item'><img src={item.logo} className='pub_logo'/></span>
               <span className='table-item'>{item.provider_no}</span>
               <span className='table-item'>{item.provider_name}</span>
               <span className='table-item'> {item.NumberOfArticles}</span>
            </div>
         )) 
        }
    
       
    </div>
  )
}

export default ActiveNewsPerPublisher