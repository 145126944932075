import React from 'react'
import './Merchants.css'
import ByDate from '../components/merchants/ByDate'
import TotalRedeemed from '../components/merchants/TotalRedeemed'
import ByDay from '../components/merchants/ByDay'
import ByValue from '../components/merchants/ByValue'
import TotalRedeemedValue from '../components/merchants/TotalRedeemedValue'
import ByTitle from '../components/merchants/ByTitle'


function Merchants() {
  return (
    <div className='merchants'>
      <h5>Ad Title: Pepino's Pizza Easter Delight</h5>
      <div className='top'>
      <ByDate/>
      <TotalRedeemed/>
      </div>
      <div className='merchant-col'>
      <ByDay/>
      </div>
      <div className='val-total'>
      <ByValue/>
      <TotalRedeemedValue/>
      </div>
      <h5 className='top-20'>Voucher Redemption By Ad Title</h5>
      <div className='top-20'>
        <ByTitle/>
      </div>
    </div>
  )
}

export default Merchants