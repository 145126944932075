import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJs,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

ChartJs.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

const formatDate = (dateString) => {
  // Create a new Date object from the dateString
  const date = new Date(dateString);
  
  // Get the year, month, and day from the date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Return the formatted date as a string
  return `${year}-${month}-${day}`;
  };


const Daily = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Distribution By Date Created',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(prod + '/news/daily');
        const apiData = response.data;

        const filteredData = apiData.filter(item => {
          const date = new Date(item.PublishDate);
          return (
            (!startDate || date >= startDate) &&
            (!endDate || date <= endDate)
          );
        });

        const labels = filteredData.map(item => formatDate(item.PublishDate));
        const dataValues = filteredData.map(item => item.ArticleCount);

        setChartData({
          labels,
          datasets: [
            {
              ...chartData.datasets[0],
              data: dataValues,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  return (
    <div className='col-flex-box col-8 shadow'>
      <div className='date-picker'>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className='datecontrols'
        />
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          className='datecontrols'
          placeholderText="End Date"
        />
      </div>
      {loading ? (
        <p style={{color: "red"}}>Loading...</p>
      ) : (
        <div>
          <Line data={chartData} options={options} />
        </div>
      )}
    </div>
  );
};

export default Daily;
