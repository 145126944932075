import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function CompanyInfoB() {
    const [companyInfo, setCompanyInfo] = useState([]);
    const [totalClicks, setTotalClicks] = useState([]);

    useEffect(() => {
        axios.get(prod + '/sportpesa/totalclicksb')
        .then((response) => {
          setTotalClicks(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, [])


    useEffect(() => {
        axios.get(prod + '/sportpesa/videob')
        .then((response) => {
          setCompanyInfo(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

  return (
    <div>
        {
          companyInfo.map((item) => (
        <div key={item.id} className='company-info'>
        <div className='company-card ch'>
            <h3 className='c-heading pd'>Company Information</h3>
            <p className='pd'><span className='desc'>Client :</span><span className='sp'> {item.company}</span> </p>
            <p className='pd'><span className='desc'>Objective Of Campaign :</span><span className='sp'> {item.objective} </span></p>
            <p className='pd'><span className='desc'>Period :</span> <span className='sp'>{item.start_date}</span> To <span className='sp'>{item.end_date}</span></p>
            <p className='pd'><span className='desc'>Type of Ad :</span><span className='sp'> Video Reward Ad </span></p>
        </div>
        <div className='ad ch'>
            <p className='pd'><span className='desc'>Budget :</span> <span className='sp'>Ksh {item.ad_budget}</span> </p>
            <p className='pd'><span className='desc'>Target Audience :</span><span className='sp'> {item.target_amount/item.amount}</span></p>
            <p className='pd'><span className='desc'>Amount Per User :</span><span className='sp'> Ksh {item.amount}</span></p>
            {
              totalClicks.map((total) => (
            <p className='pd' key={total._id}><span className='desc'>Engagement :</span> <span className='sp'>{Math.floor(total.count/(item.target_amount/item.amount) * 100)} %</span></p>
              ))}
        </div>
        <div className='ch'>
        <p className='pd'><span className='desc'>Ad Description :</span><span className='sp2'> {item.description}</span></p>
        </div>
    </div>
     )) 
    }
    </div>
    
  )
}

export default CompanyInfoB