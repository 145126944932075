import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Dummy from '../../images/bars.svg';
import AudioLoader from '../loaders/AudioLoader';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'


function AverageDailyRetention() {
    const [todayUsers, setTodayUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(prod + '/userappactivity/dailyretention')
        .then((response) => {
          setTodayUsers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
          setError('Error fetching data');
          setLoading(false);
        });
      }, []);
      
  return (
    <div className='card2 card-user mgtop'> 
    <div className='card-bar'>
       <img className="dummy" src={Dummy} alt='bars'/>
      </div>
      <div className='card-text'>
      <h4> Average Daily Retention</h4>
      {loading ? (
                    <p><AudioLoader/></p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <div>
                      {todayUsers.map((item) => (
                            <div key={item.id}>
                                <h5>{Math.round(item.averageDailyRetentionRate)}</h5>
                            </div>
                        ))}
                    </div>
          
        )}
      </div>
    </div>
  )
}

export default AverageDailyRetention