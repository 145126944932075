import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import './Styles.css';
import Dummy from '../../images/bars.svg';

const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'


function TotalUsers() {
    const [userCount, setUserCount] = useState([]);

    useEffect(() => {
        axios.get(prod + '/user/users')
        .then((response) => {
          setUserCount(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []); 

  return (
    <div className='card card-user'>
      <div className='card-bar'>
       <img className="dummy" src={Dummy} alt='bars'/>
      </div>
      <div className='card-text'>
      <h4>Total Users</h4>
        {
          userCount.map((item) => (
            <div key={item.id} >
              <h5>{item.totalcount}</h5>
            </div>
         )) 
        }

      </div>
        
        
    </div>
  )
}

export default TotalUsers