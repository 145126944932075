import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import {FaCheckCircle} from 'react-icons/fa'
import { FaCircleXmark} from 'react-icons/fa6'
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function DeletionStatus() {
    const [allowed, setAllowed] = useState([]);
    const [deleted, setDeleted] = useState([]);
    useEffect(() => {
        axios.get(prod + '/news/allowed')
        .then((response) => {
          setAllowed(response.data);
          
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

      useEffect(() => {
        axios.get(prod + '/news/denied')
        .then((response) => {
          setDeleted(response.data);
          
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

  return (
    <div className='den-all'>
        <div>
        {
          allowed.map((item) => (
            <p>Allowed Permission <FaCheckCircle className='icon-g'/> : {item.allowed}</p>
         )) 
        }
        </div>
        <div>
        {
          deleted.map((item) => (
            <p>Denied Permission <FaCircleXmark className='icon-r'/> : {item.denied}</p>
         )) 
        }
        </div>
    </div>
  )
}

export default DeletionStatus