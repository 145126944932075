import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import ModalThree from './ModalThree';
import {FaPlus} from 'react-icons/fa'
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'


function Today() {
    const [newsToday, setNewsToday] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


    useEffect(() => {
        axios.get(prod + '/news/addedToday')
        .then((response) => {
          setNewsToday(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='news-card'>
      <div>
       Today
      </div>
      <div className='news-card-content'>
        <div>
        {
          newsToday.map((item) => (
            <div key={item.id} >
              <h5>{item.NumberOfArticles}</h5>
            </div>
         )) 
        }
        </div>
        <div>
        <FaPlus className='more' onClick={openModal}/>
        </div>
      </div>
      <ModalThree isOpen={modalOpen} onClose={closeModal} /> 
    </div>
  )
}

export default Today