import React from 'react';
import './Users.css';

import TotalUsers from '../components/users/TotalUsers';
import TodaysUsers from '../components/users/TodaysUsers';
import UsersThisWeek from '../components/users/UsersThisWeek';
import UsersLastWeek from '../components/users/UsersLastWeek';
import Yesterday from '../components/users/Yesterday';
import Timeseries from '../components/users/Timeseries';
import { FaUser} from 'react-icons/fa6';
import ByGender from '../components/users/ByGender';
import ByAge from '../components/users/ByAge';
import TopCountiesAll from '../components/users/TopCountiesAll';
import ByYear from '../components/users/ByYear';
import UsersByLocation from '../components/users/UsersByLocation';
import ByMonth from '../components/users/ByMonth';
import DailyRunningTotals from '../components/users/DailyRunningTotals';
import AverageDailyRetention from '../components/users/AverageDailyRetention';
import AverageWeeklyRetention from '../components/users/AverageWeeklyRetention';
import AverageMonthlyRetention from '../components/users/AverageMonthlyRetention';
import AverageYearlyRetention from '../components/users/AverageYearlyRetention';
import HourlyDistribution from '../components/users/HourlyDistribution';
//import UserList from '../components/users/UserList'
import ByGenderB from '../components/users/ByGenderB';
import UserMonthlyRunningTotals from '../components/users/UserMonthlyRunningTotals'

function Users() {
  return (
    <div className='users-main'>
      <div className='icon'>
      <FaUser className='usericon'/>
      <h3 className='user-icon'> Users Analysis</h3>
      </div>
      <div className='card-container'>
        <TotalUsers/>
        <TodaysUsers/>
        <Yesterday/>
        <UsersThisWeek/>
        <UsersLastWeek/>
      </div>
      <div className='gender_year'>
        <ByGender/>
        <ByYear/>
        <ByGenderB/>
      </div>
      <div className='card-container'>
         <AverageDailyRetention/>
         <AverageWeeklyRetention/>
         <AverageMonthlyRetention/>
         <AverageYearlyRetention/>
      </div>
      <ByMonth/>
      <UserMonthlyRunningTotals/>
      <HourlyDistribution/>
      <DailyRunningTotals/>
      <Timeseries/>
      <TopCountiesAll/>
      <ByAge/>
      <UsersByLocation/>
      {/* <UserList/> */}
    </div>
  )
}

export default Users