import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import {FaPlus} from 'react-icons/fa'
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function Activeproviders({onClick}) {
    const [activeProviders, setActiveProviders] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
      setModalOpen(true);
    };

    const closeModal = () => {
      setModalOpen(false);
    };
  
    useEffect(() => {
        axios.get(prod + '/news/activeproviders')
        .then((response) => {
          setActiveProviders(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='news-card'>
      <div>
          Active Providers
      </div>
      <div className='news-card-content'>
        <div>
        {
          activeProviders.map((item) => (
            <div key={item.id} >
              <h5>{item.activeproviders}</h5>
            </div>
         )) 
        }
        </div>
        <div>
        <FaPlus className='more' onClick={openModal}/>
        </div>
      
      </div>
    <Modal isOpen={modalOpen} onClose={closeModal} />
    </div>
  )
}

export default Activeproviders