import React from 'react'
import './Users.css';
import './Reports.css'
import PepinosByDate from '../components/reports/PepinosByDate'
import PepinossByLocation from '../components/reports/PepinossByLocation'
import PepinosByGender from '../components/reports/PepinosByGender'
import PepinosTotalClicks from '../components/reports/PepinosTotalClicks';
import PepinosRepeatClicks from '../components/reports/PepinosRepeatClicks';
import PepinosClosedClicks from '../components/reports/PepinosClosedClicks';
import PepinosWrongAnswers from '../components/reports/PepinosWromgAnswers';
import PepinosGrandTotalClicks from '../components/reports/PepinosGrandTotalClicks';
import CompanyInfo from '../components/reports/CompanyInfo';
import CompanyInfoA from '../components/reportsa/CompanyInfoA';
import PepinosByClosedClicksA from '../components/reportsa/PepinosByClosedClicksA'
import PepinosByDateA from '../components/reportsa/PepinosByDateA';
import PepinosByGenderA from '../components/reportsa/PepinosByGenderA'
import PepinosByGrandTotalClicks from '../components/reportsa/PepinosByGrandTotalClicks'
import PepinosByLocationA from '../components/reportsa/PepinosByLocationA';
import PepinosRepeatClicksA from '../components/reportsa/PepinosRepeatClicksA'
import PepinosTotalClicksA from '../components/reportsa/PepinosTotalClicksA'
import PepinosWrongAnswersA from '../components/reportsa/PepinosWrongAnswersA'
import CompanyInfoT from '../components/reportst/CompanyInfoT'
import PepinosByGenderT from '../components/reportst/PepinosByGenderT'
import PepinosByDateT from '../components/reportst/PepinosByDateT'
import PepinosByLocationT from '../components/reportst/PepinosByLocationT'
import PepinosClosedClicksT from '../components/reportst/PepinosClosedClicksT'
import PepinosGrandTotalClicksT from '../components/reportst/PepinosGrandTotalClicksT'
import PepinosRepeatClicksT from '../components/reportst/PepinosRepeatClicksT'
import PepinosTotalClicksT from '../components/reportst/PepinosTotalClicksT'
import PepinosWrongAnswersT from '../components/reportst/PepinosWrongAnswersT'
import PepinosByAgeT from '../components/reportst/PepinosByAgeT'
import PepinosByAgeA from '../components/reportsa/PepinosByAgeA'
import PepinosByAge from '../components/reports/PepinosByAge'

function Pepinos() {
  return (
    <div>
        <div className='reports'>
      <CompanyInfo/>
        <div className='report-head'>
            <h4>Pepinos Analytics : Ad ID : 61</h4>
        </div>
        <div className='card-container'>
        <PepinosGrandTotalClicks/>
        <PepinosTotalClicks/>
        <PepinosRepeatClicks/>
        <PepinosClosedClicks/>
        <PepinosWrongAnswers/>
        </div>
        <div className='report-row'>
        <PepinosByDate/>
        <PepinosByGender/>
        </div>
        <PepinossByLocation/>
        <PepinosByAge/>
    </div>
    <div className='reports'>
      <CompanyInfoA/>
        <div className='report-head'>
            <h4>Pepinos Analytics : Ad ID : 30</h4>
        </div>
        <div className='card-container'>
        <PepinosByGrandTotalClicks/>
        <PepinosTotalClicksA/>
        <PepinosRepeatClicksA/>
        <PepinosByClosedClicksA/>
        <PepinosWrongAnswersA/>
        </div>
        <div className='report-row'>
        <PepinosByDateA/>
        <PepinosByGenderA/>
        </div>
        <PepinosByLocationA/>
        <PepinosByAgeA/>
    </div>
    <div className='reports'>
      <CompanyInfoT/>
        <div className='report-head'>
            <h4>Pepinos Analytics : Ad ID : 48</h4>
        </div>
        <div className='card-container'>
          <PepinosGrandTotalClicksT/>
          <PepinosTotalClicksT/>
          <PepinosRepeatClicksT/>
          <PepinosClosedClicksT/>
          <PepinosWrongAnswersT/>
        </div>
        <div className='report-row'>
          <PepinosByDateT/>
          <PepinosByGenderT/>
        </div>
        <PepinosByLocationT/>
        <PepinosByAgeT/>
    </div>
    </div>
  )
}

export default Pepinos