import React from 'react'
import { useState, useEffect } from 'react';
import { Area } from '@ant-design/plots';


function ByDate() {
    const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch('https://dash-api.teleeza.africa/merchants/bydate');
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const config = {
    data,
    xField: 'RedeemDate',
    yField: 'RedeemedVouchers',
    style: {
      fill: 'linear-gradient(-90deg, white 0%, darkgreen 100%)',
      // linear-gradient(-90deg, white 0%, darkgreen 100%)
    },
    axis: {
        y: { labelFormatter: '~s' },
        x: { labelFormatter: (text) => {
            const date = new Date(text);
            return date.toLocaleString('default', { month: 'short'});
          }}
      },
    line: {
      style: {
        stroke: 'darkgreen',
        strokeWidth: 2,
      },
    },
  };
  return (
    <div className='col-flex-box col-m shadow top-10'>
        <h5>Pepinos Redeemed Vouchers By Date</h5>
        <Area {...config} />
    </div>
  )
}

export default ByDate