import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';

const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function ByTitle() {
    const [allTitles, setAllTitles] = useState([]);
    
    //pul data from total redeemed API
    useEffect(() => {
        axios.get(prod + '/merchants/byadtitle')
        .then((response) => {
          setAllTitles(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

    
  return (
    <div className='top-10 '>
        <div>
        {
          allTitles.map((item) => (
            <div>
                <h5>{item.ad_title}</h5>
                <div key={item.id} className='r-num r-col-n'>
              <h5 className='r-text'>{item.count}</h5>
            </div>
            </div>
            
         )) 
        }
        </div>
        
    </div>
  )
}

export default ByTitle