// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container{
    display:flex;
}
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    background: rgba(65, 92, 217, 0.8);;
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
    position: fixed;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
}
.logo img{
    max-height: 60px;
    width: 70%;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.bars:hover{
    cursor: pointer;
}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background:lightskyblue;
    color: #000;
    transition: all 0.5s;
}
.active{
    background: lightskyblue;
    color: #000;
}
.icon, .link_text{
    font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,SAAS;IACT,qBAAqB;AACzB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;IACX,aAAa;AACjB;AACA;IACI,kCAAkC;IAClC,WAAW;IACX,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,oBAAoB;AACxB;AACA;IACI,uBAAuB;IACvB,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,wBAAwB;IACxB,WAAW;AACf;AACA;IACI,eAAe;AACnB","sourcesContent":["*{\n    margin:0;\n    padding:0;\n    text-decoration: none;\n}\n.container{\n    display:flex;\n}\nmain{\n    width: 100%;\n    padding: 20px;\n}\n.sidebar{\n    background: rgba(65, 92, 217, 0.8);;\n    color: #fff;\n    height: 100vh;\n    width: 200px;\n    transition: all 0.5s;\n    position: fixed;\n}\n.top_section{\n    display: flex;\n    align-items:center;\n    padding:20px 15px;\n}\n.logo img{\n    max-height: 60px;\n    width: 70%;\n}\n.bars{\n    display: flex;\n    font-size: 25px;\n    margin-left: 50px;\n}\n.bars:hover{\n    cursor: pointer;\n}\n.link{\n    display: flex;\n    color: #fff;\n    padding: 10px 15px;\n    gap: 15px;\n    transition: all 0.5s;\n}\n.link:hover{\n    background:lightskyblue;\n    color: #000;\n    transition: all 0.5s;\n}\n.active{\n    background: lightskyblue;\n    color: #000;\n}\n.icon, .link_text{\n    font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
