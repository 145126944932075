import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SpinnerLoader from '../loaders/SpinnerLoader';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
};

function ByPageID() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Overall App Activity By Page',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    // Fetch data from the backend API
    const fetchData = async () => {
      try {
        const response = await axios.get(prod + '/userappactivity/bypageID', {
          params: {
            CreatedAt: selectedDate.setHours(0, 0, 0, 0 ), // Send selected date to backend in YYYY-MM-DD format
          },
        });
        const apiData = response.data;

        // Extracting data from the API response and updating the chartData state
        const labels = apiData.map(item => item._id);
        const dataValues = apiData.map(item => item.count);

        setChartData({
          labels,
          datasets: [
            {
              ...chartData.datasets[0], // Preserve other dataset properties
              data: dataValues,
            },
          ],
        });
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        setError(error); // Set error state if there's an error
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchData();
  }, [selectedDate]); // Fetch data whenever selectedDate changes

  if (loading) {
    return <div className="activity-pos activity-col shadow"><p><SpinnerLoader/></p></div>; // Show loading indicator while data is being fetched
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Show error message if there's an error
  }

  return (
    <div className='activity-pos activity-col shadow'>
      <DatePicker selected={selectedDate} onChange={date => setSelectedDate(date)} />
      <Bar data={chartData} options={options} />
    </div>
  );
}

export default ByPageID;
