// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dummy{
    height: 50px;
    width: 30px;
}
.card-user{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.mgtop{
    margin-top: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/users/Styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".dummy{\n    height: 50px;\n    width: 30px;\n}\n.card-user{\n    display:flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n}\n\n.mgtop{\n    margin-top: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
