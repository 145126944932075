import React from 'react'
import './UserAppActivity.css'
import ByDate from '../components/appactivity/ByDate'
import ByGender from '../components/appactivity/ByGender'
import ByPageID from '../components/appactivity/ByPageID'
import ByLocation from '../components/appactivity/ByLocation'
import QuickSummary from '../components/appactivity/QuickSummary'
import DailyActiveUsers from '../components/appactivity/DailyActiveUsers'

function UserAppActivity() {
  return (
    <div className='userappactivity'>
      <div className='act-row'>
        <ByGender/>
        <QuickSummary/>
      </div>
        <ByDate/>
        <ByLocation/>
        <ByPageID/>
        <DailyActiveUsers/>
    </div>
  )
}

export default UserAppActivity