import React from 'react'
import './Users.css';
import './Reports.css'

import CompanyInfoS from '../components/sportpesa/CompanyInfoS'
import ByDate from '../components/sportpesa/ByDate'
import ByAge from '../components/sportpesa/ByAge'
import ByGender from '../components/sportpesa/ByGender'
import ByLocation from '../components/sportpesa/ByLocation'
import GrandTotalClicks from '../components/sportpesa/GrandTotalClicks'
import TotalClicks from '../components/sportpesa/TotalClicks'
import ClosedClicksU from '../components/sportpesa/ClosedClicksU';
import WrongAnswers from '../components/sportpesa/WrongAnswers';
import UniqueClicks from '../components/sportpesa/UniqueClicks';
import CompanyInfoB from '../components/sportpesab/CompanyInfoB'
import ByAgeB from '../components/sportpesab/ByAgeB'
import ByDateB from '../components/sportpesab/ByDateB'
import ByGenderB from '../components/sportpesab/ByGenderB'
import ByLocationB from '../components/sportpesab/ByLocationB'
import GrandTotalB from '../components/sportpesab/GrandTotalB';
import ClosedClicksB from '../components/sportpesab/ClosedClicksB';
import TotalClicksB from '../components/sportpesab/TotalClicksB'
import WrongAnswersB from '../components/sportpesab/WrongAnswersB'
import UniqueClicksB from '../components/sportpesab/UniqueClicksB'
import CompanyInfoTS from '../components/sportpesat/CompanyInfoTS'
import ByAgeT from '../components/sportpesat/ByAgeT';
import ByDateT from '../components/sportpesat/ByDateT';
import ByGenderT from '../components/sportpesat/ByGenderT'
import ByLocationT from '../components/sportpesat/ByLocationT'
import GrandTotalT from '../components/sportpesat/GrandTotalT'
import TotalClicksT from '../components/sportpesat/TotalClicksT'
import ClosedClicksT from '../components/sportpesat/ClosedClicksT'
import UniqueClicksT from '../components/sportpesat/UniqueClicksT';
import WrongAnswersT from '../components/sportpesat/WrongAnswersT';
import ImpressionsToday from '../components/sportpesa/ImpressionsToday';
import TotalImpressions from '../components/sportpesa/TotalImpressions';
import UniqueImpressions from '../components/sportpesa/UniqueImpressions';
import UniqueImpressionsToday from '../components/sportpesa/UniqueImpressionsToday';
import ImpressionsTodayB from '../components/sportpesab/ImpressionsTodayB';
import TotalImpressionsB from '../components/sportpesab/TotalImpressionsB';
import UniqueImpressionsB from '../components/sportpesab/UniqueImpressionsB';
import UniqueImpressionsTodayB from '../components/sportpesab/UniqueImpressionsTodayB';
import ImpressionsTodayT from '../components/sportpesat/ImpressionsTodayT';
import TotalImpressionsT from '../components/sportpesat/TotalImpressionsT';
import UniqueImpressionsT from '../components/sportpesat/UniqueImpressionsT';
import UniqueImpressionsTodayT from '../components/sportpesat/UniqueImpressionsTodayT';


function Reports() {
  return (
    <div>
      <div className='reports bg1'>
      <CompanyInfoS/>
        <div className='report-head'>
            <h4>Sportpesa Analytics : Ad ID : 71</h4>
        </div>
        <div className='card-container'>
          <GrandTotalClicks/>
          <TotalClicks/>
          <UniqueClicks/>
          <ClosedClicksU/>
          <WrongAnswers/>
        </div>
        <div className='report-row'>
          <ByDate/>
          <ByGender/>
        </div>
        <div className='card-container'>
          <TotalImpressions/>
          <UniqueImpressions/>
          <ImpressionsToday/>
          <UniqueImpressionsToday/>
        </div>
          <ByAge/>
          <ByLocation/>
    </div>

    <div className='reports bg2'>
      <CompanyInfoB/>
        <div className='report-head'>
            <h4>Sportpesa Analytics : Ad ID : 70</h4>
        </div>
        <div className='card-container'>
          <GrandTotalB/>
          <TotalClicksB/>
          <UniqueClicksB/>
          <ClosedClicksB/>
          <WrongAnswersB/>
        </div>
        <div className='report-row'>
          <ByDateB/>
          <ByGenderB/>
        </div>
        <div className='card-container'>
          <TotalImpressionsB/>
          <UniqueImpressionsB/>
          <ImpressionsTodayB/>
          <UniqueImpressionsTodayB/>
        </div>
          <ByAgeB/>
          <ByLocationB/>
    </div>

    <div className='reports bg3'>
      <CompanyInfoTS/>
        <div className='report-head'>
            <h4>Sportpesa Analytics : Ad ID : 50</h4>
        </div>
        <div className='card-container'>
          <GrandTotalT/>
          <TotalClicksT/>
          <UniqueClicksT/>
          <ClosedClicksT/>
          <WrongAnswersT/>
        </div>
        <div className='report-row'>
          <ByDateT/>
          <ByGenderT/>
        </div>
        <div className='card-container'>
          <TotalImpressionsT/>
          <UniqueImpressionsT/>
          <ImpressionsTodayT/>
          <UniqueImpressionsTodayT/>
        </div>
          <ByAgeT/>
          <ByLocationT/>
    </div>
    </div>
    
  )
}

export default Reports