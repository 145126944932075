import React from 'react'
import './UserNewsActivity.css'
import ByLocation from '../components/useractivity/ByLocation'
import ByDate from '../components/useractivity/ByDate'
import ByPublisher from '../components/useractivity/ByPublisher'
import ByInterest from '../components/useractivity/ByInterest'
import ByAge from '../components/useractivity/ByAge'
import ByGender from '../components/useractivity/ByGender'
import ActiveReadersByDate from '../components/useractivity/ActiveReadersByDate'
import TotalReaders from '../components/useractivity/TotalReaders'
import TotalNewsClicks from '../components/useractivity/TotalNewsClicks'
import TotalNewsClicksToday from '../components/useractivity/TotalNewsClicksToday'
import ReadersToday from '../components/useractivity/ReadersToday'

function UserNewsActivity() {
  return (
    <div  className='usernewsactivity'>
        <div className='user-news-activity-cards'>
        <TotalReaders/>
        <TotalNewsClicks/>
        <TotalNewsClicksToday/>
        <ReadersToday/>
        </div>
        <ByDate/>
        <ActiveReadersByDate/>
        <ByLocation/>
        <ByPublisher/>
        <ByInterest/>
        <ByAge/>
        <ByGender/>
    </div>
  )
}

export default UserNewsActivity