import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJs,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import DatePicker from 'react-datepicker'; // Import date picker library
import 'react-datepicker/dist/react-datepicker.css'; // Import date picker styles
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

ChartJs.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Time Series Analysis",
    },
  },
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

function ByDateT() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Ad Clicks Distribution By Date',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  });
  const [loading, setLoading] = useState(false); // Loading state
  const [startDate, setStartDate] = useState(null); // Start date state
  const [endDate, setEndDate] = useState(null); // End date state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading state to true while fetching data
      try {
        const response = await axios.get(prod + '/shopzetu/bydatet');
        const apiData = response.data;
        const labels = apiData.map(item => formatDate(item._id));
        const dataValues = apiData.map(item => item.activityCount);
        setChartData({
          labels,
          datasets: [
            {
              ...chartData.datasets[0],
              data: dataValues,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false); // Set loading state to false after fetching data
    };

    fetchData();
  }, []);

  // Filter data by start and end dates
  const filteredData = chartData.labels.reduce((acc, label, index) => {
    const date = new Date(label);
    if (startDate && date < startDate) return acc;
    if (endDate && date > endDate) return acc;
    acc.labels.push(label);
    acc.datasets[0].data.push(chartData.datasets[0].data[index]);
    return acc;
  }, { labels: [], datasets: [{ ...chartData.datasets[0], data: [] }] });

  return (
    <div className='row-flex-box  report-card'>
      <div className='date-picker'>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
        />
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
        />
      </div>
      <div className='chart-content'>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Line  data={filteredData} options={options} />
        )}
      </div>
    </div>
  );
}

export default ByDateT;
