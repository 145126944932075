// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usernewsactivity{
display: flex;
flex-direction: column;
margin-bottom: 2px;
margin-top: 10px;
width: 100%;
}

.user-news-activity-cards{
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 97%;
}

.user-activity-card{
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 24%;
    border: none;
    margin: auto;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.user-activity-card:hover{
    box-shadow: rgba(0, 0, 0, 1) 0px 3px 8px;
    background: rgba(255,69,0, 0.9);
}




@media only screen and (max-width: 1000px) {
    .row-flex-box{
        display: flex;
        flex-direction: column;
    }
    .col-3{
        width: 100%;
    }
    .col-2{
        width: 100%;
    }

    .user-news-activity-cards{
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;
        align-items: center;
        width: 82%;
    }
    .user-activity-card{
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/UserNewsActivity.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,sBAAsB;AACtB,kBAAkB;AAClB,gBAAgB;AAChB,WAAW;AACX;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,wCAAwC;IACxC,+BAA+B;AACnC;;;;;AAKA;IACI;QACI,aAAa;QACb,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,YAAY;QACZ,uBAAuB;QACvB,mBAAmB;QACnB,UAAU;IACd;IACA;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;IACvB;EACF","sourcesContent":[".usernewsactivity{\ndisplay: flex;\nflex-direction: column;\nmargin-bottom: 2px;\nmargin-top: 10px;\nwidth: 100%;\n}\n\n.user-news-activity-cards{\n    display: flex;\n    flex-direction: row;\n    margin: auto;\n    justify-content: center;\n    align-items: center;\n    width: 97%;\n}\n\n.user-activity-card{\n    display: flex;\n    flex-direction: column;\n    height: 100px;\n    width: 24%;\n    border: none;\n    margin: auto;\n    text-align: center;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n}\n\n.user-activity-card:hover{\n    box-shadow: rgba(0, 0, 0, 1) 0px 3px 8px;\n    background: rgba(255,69,0, 0.9);\n}\n\n\n\n\n@media only screen and (max-width: 1000px) {\n    .row-flex-box{\n        display: flex;\n        flex-direction: column;\n    }\n    .col-3{\n        width: 100%;\n    }\n    .col-2{\n        width: 100%;\n    }\n\n    .user-news-activity-cards{\n        display: flex;\n        flex-direction: column;\n        margin: auto;\n        justify-content: center;\n        align-items: center;\n        width: 82%;\n    }\n    .user-activity-card{\n        width: 100%;\n        padding: 10px;\n        margin-bottom: 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
