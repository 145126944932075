// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gender_activity{
    width: 45%;
    margin: auto;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}
.activity-pos{
    margin: auto;
    padding: 10px;
    margin-bottom: 10px;
}
.activity-col{
    width: 94%;
}

@media only screen and (max-width: 1000px) {
    .gender_activity{
        width: 99%;
        }
    
  }`, "",{"version":3,"sources":["webpack://./src/components/appactivity/Styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;;AAEA;IACI;QACI,UAAU;QACV;;EAEN","sourcesContent":[".gender_activity{\n    width: 45%;\n    margin: auto;\n    margin-bottom: 20px;\n    align-items: center;\n    justify-content: center;\n}\n.activity-pos{\n    margin: auto;\n    padding: 10px;\n    margin-bottom: 10px;\n}\n.activity-col{\n    width: 94%;\n}\n\n@media only screen and (max-width: 1000px) {\n    .gender_activity{\n        width: 99%;\n        }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
