// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userappactivity{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.act-row{
    display: flex;
    flex-direction: row;
    padding: 2px;
    margin: auto;
    margin-top: 10px;
    width: 100%;
}

.app-activity-card{
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 45%;
    border: none;
    margin: auto;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.app-activity-card:hover{
    box-shadow: green 0px 3px 8px;
    background: rgba(255,69,0, 0.9);
}

.app-act-col{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
    .act-row{
        display: flex;
        flex-direction: column;
        }
    
  }`, "",{"version":3,"sources":["webpack://./src/pages/UserAppActivity.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,6BAA6B;IAC7B,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB;;EAEN","sourcesContent":[".userappactivity{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n.act-row{\n    display: flex;\n    flex-direction: row;\n    padding: 2px;\n    margin: auto;\n    margin-top: 10px;\n    width: 100%;\n}\n\n.app-activity-card{\n    display: flex;\n    flex-direction: column;\n    height: 100px;\n    width: 45%;\n    border: none;\n    margin: auto;\n    text-align: center;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n}\n\n.app-activity-card:hover{\n    box-shadow: green 0px 3px 8px;\n    background: rgba(255,69,0, 0.9);\n}\n\n.app-act-col{\n    display: flex;\n    flex-direction: row;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n@media only screen and (max-width: 1000px) {\n    .act-row{\n        display: flex;\n        flex-direction: column;\n        }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
