import React from 'react'
import './Login.css'
import './Common.css'


function Login() {
  return (
    <div>
      Login Page
    </div>
  )
}

export default Login