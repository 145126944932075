import React from 'react'
import './Styles.css'
import TotalActivity from './TotalActivity'
import TotalActivityToday from './TotalActivityToday'
import TotalUsers from './TotalUsers'
import TotalUsersToday from './TotalUsersToday'

function QuickSummary() {
  return (
    <div className='gender_activity shadow'>
        <div className='app-act-col'>
            <TotalActivity/>
            <TotalActivityToday/>
        </div>
        <div className='app-act-col'>
            <TotalUsers/>
            <TotalUsersToday/>
        </div>
    </div>
  )
}

export default QuickSummary