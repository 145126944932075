import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';

const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function TotalActivityToday() {
    const [allProviders, setAllProviders] = useState([]);

    useEffect(() => {
        axios.get(prod + '/userappactivity/totalactivitytoday')
        .then((response) => {
          setAllProviders(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
  return (
    <div className='app-activity-card shadow'>Total Activity Today
         {
          allProviders.map((item) => (
            <div key={item._id} >
              <h5>{item.totalActivityToday}</h5>
            </div>
         )) 
        }
    </div>
  )
}

export default TotalActivityToday