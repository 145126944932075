import React from 'react';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {
    Chart as ChartJs,
    ArcElement,
    Tooltip,
    Legend
  } from 'chart.js';
  import {Doughnut} from 'react-chartjs-2';
  const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

  ChartJs.register(
    ArcElement,
    Tooltip,
    Legend
  )

function ByGender() {
    const [allGenders, setAllGenders] = useState([])
    const [textData, setTextData] = useState([])
    const [chartData, setChartData] = useState({
      labels: [],
      datasets: [
        {
          label: 'Ad Click Distribution By Gender',
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'white',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            ],
          borderWidth: 1,
        },
      ],
    });

    const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      title: {
        display: true,
        text: "Ad Click Distribution By Gender",
      },
    },
    }

    useEffect(() => {
      axios.get(prod + '/sportpesa/totalclicks')
      .then((response) => {
        setAllGenders(response.data);
      })
      .catch((error) => {
        console.log('Error fetching data', error);
      });
    }, []); 

    useEffect(() => {
      // Fetch data from the backend API
      const fetchData = async () => {
        try {
          const response = await axios.get(prod + '/sportpesa/bygender'); 
          const apiData = response.data;
          setTextData(response.data)
  
          // Extracting data from the API response and updating the chartData state
          const labels = apiData.map(item => item._id);
          const dataValues = apiData.map(item => item.count);
  
          setChartData({
            labels,
            datasets: [
              {
                ...chartData.datasets[0], // Preserve other dataset properties
                data: dataValues,
              },
            ],
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []); // Empty dependency array ensures the effect runs once on component mount
  
  return (
    <div className='row-flex-box report-card'  >
      <Doughnut data={chartData} style={{height: '200px'}} options={options}>
      </Doughnut>
      {
          textData.map((item) => (
            <div key={item._id} >
              <h5 className='report-element'>{item._id}  :  {item.count} - 
              <span>
                {
                  allGenders.map((data1) => (
                    <span key={data1._id}> {Math.round((item.count/data1.count) *100)} %</span>
                  ))
                }
              </span>
              </h5>
            </div>
         )) 
        }
    </div>
  )
}

export default ByGender