import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function TotalRedeemedValue() {
    const [allValue, setAllValue] = useState([]);

    useEffect(() => {
        axios.get(prod + '/merchants/totalredeemedvalue')
        .then((response) => {
          setAllValue(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
      
  return (
    <div className='total-card top-20'>
        {
          allValue.map((item) => (
            <div key={item.id} >
              <h5>Total Redeemed Value  <span className='val-colour'> Ksh {item.TotalRedeemedValue}</span></h5>
            </div>
         )) 
        }
    </div>
  )
}

export default TotalRedeemedValue