import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import {FaCheckCircle} from 'react-icons/fa'
import { FaCircleXmark} from 'react-icons/fa6'
import DeletionStatus from './DeletionStatus';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function PublishersByStatus() {
    const [activeNewsPerPublisher, setActiveNewsPerPublisher] = useState([]);
    useEffect(() => {
        axios.get(prod + '/news/bypublisherstatus')
        .then((response) => {
          setActiveNewsPerPublisher(response.data);
          
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

  return (
    <div className='pub-table'>
      <p><DeletionStatus/></p>
      <div className='table'>
        <span className='table-item'>Logo</span>
        <span className='table-item'>Publisher ID</span>
        <span className='table-item'>Publisher Name</span>
        <span className='table-item'>Status</span>
    </div>
        {
          activeNewsPerPublisher.map((item) => (
              <div key={item.id} className='table'>
               <span className='table-item'><img src={item.logo_url} alt="" className='pub_logo'/></span>
               <span className='table-item'>{item.id}</span>
               <span className='table-item'>{item.name}</span>
               {item.flag == 1 ? (
              <span className='table-item'><FaCheckCircle className='icon-g'/></span>
              ) : (
              <span className='table-item'><FaCircleXmark className='icon-r'/></span>
               )}
            </div> 
         )) 
        }
       
    </div>
  )
}

export default PublishersByStatus