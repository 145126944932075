import React, { useState } from 'react';
import './Sidebar.css'
import {
    FaTh,
    FaBars,
    FaRegChartBar,
    FaThList,
    FaUserFriends,
    FaNewspaper,
    FaShoppingBag,
    FaAppStore,
    FaAd
}from "react-icons/fa";
import { NavLink } from 'react-router-dom';
const logo = 'https://teleeza.africa/assets/img/logo.png';


const Sidebar = ({children}) => {
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    const menuItem=[
        {
            path:"/user",
            name:"Users",
            icon:<FaUserFriends/>
        },
        {
            path:"/news",
            name:"News",
            icon:<FaNewspaper/>
        },
        {
            path:"/usernewsactivity",
            name:"News Activity",
            icon:<FaRegChartBar/>
        },
        {
            path:"/userappactivity",
            name:"App Activity",
            icon:<FaTh/>
        },
        {
            path:"/merchants",
            name:"Merchants",
            icon:<FaAppStore/>
        },
        {
            path:"/shopzetu",
            name:"ShopZetu",
            icon:<FaShoppingBag/>
        },
        {
            path:"/reports",
            name:"SportPesa",
            icon:<FaThList/>
        },
        {
            path:"/pepinos",
            name:"Pepinos",
            icon:<FaAd/>
        }
    ]
    return (
        <div className="container">
           <div style={{width: isOpen ? "300px" : "100px", background: isOpen ? "rgba(65, 92, 217, 0.4)" : ""}} className="sidebar">
           <div>
               <h6 style={{display: isOpen ? "block" : "none"}} className="logo"><img  src={logo} alt="Teleeza"/></h6>
            </div>
               <div className="top_section">
                   <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div>
               </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
           </div>
           <main>{children}</main>
        </div>
    );
};

export default Sidebar;