import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';

const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function TotalRedeemed() {
    const [totalRedeemed, setTotalRedeemed] = useState([]);
    const [redeemedAudio, setRedeemedAudio] = useState([]);
    const [redeemedText, setRedeemedText] = useState([]);
    const [redeemedVideo, setRedeemedVideo] = useState([]);

    //pul data from total redeemed API
    useEffect(() => {
        axios.get(prod + '/merchants/totalredeemed')
        .then((response) => {
          setTotalRedeemed(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

    useEffect(() => {
        axios.get(prod + '/merchants/redeemedaudio')
        .then((response) => {
          setRedeemedAudio(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

    useEffect(() => {
        axios.get(prod + '/merchants/redeemedtext')
        .then((response) => {
          setRedeemedText(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
    
    useEffect(() => {
        axios.get(prod + '/merchants/redeemedvideo')
        .then((response) => {
          setRedeemedVideo(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);

  return (
    <div className='top-10 total-redeemed'>
        <div>
        Total Redeemed Vouchers
        {
          totalRedeemed.map((item) => (
            <div key={item.id} className='r-num r-col-c'>
              <h5 className='r-text'>{item.totalredeemed}</h5>
            </div>
         )) 
        }
        </div>

        <div>
        Video Redeemed Vouchers
        {
          redeemedVideo.map((item) => (
            <div key={item.id} className='r-num r-col-g'>
              <h5 className='r-text'>{item.totalredeemed}</h5>
            </div>
         )) 
        }
        </div>

        <div>
        Text Redeemed Vouchers
        {
          redeemedText.map((item) => (
            <div key={item.id} className='r-num r-col-n'>
              <h5 className='r-text'>{item.totalredeemed}</h5>
            </div>
         )) 
        }
        </div>

        <div>
        Audio Redeemed Vouchers
        {
          redeemedAudio.map((item) => (
            <div key={item.id} className='r-num r-col-y'>
              <h5 className='r-text'>{item.totalredeemed}</h5>
            </div>
         )) 
        }
        </div>
        
    </div>
  )
}

export default TotalRedeemed