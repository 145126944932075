import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'
 
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    elements: {
      bar: {
        borderRadius: 40,
        borderWidth: 0.7,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "User Distribution Monthly",
      },
    },
  } 
  

function ByMonth() {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
          {
            label: 'Distribution By Year/Month',
            data: [],
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
            ],
          },
        ],
      });
    
      useEffect(() => {
        // Fetch data from the backend API
        const fetchData = async () => {
          try {
            const response = await axios.get(prod + '/user/monthly'); 
            const apiData = response.data;
    
            // Extracting data from the API response and updating the chartData state
            const labels = apiData.map(item => item.MonthYear);
            const dataValues = apiData.map(item => item.NewUserCount);
    
            setChartData({
              labels,
              datasets: [
                {
                  ...chartData.datasets[0], // Preserve other dataset properties
                  data: dataValues,
                },
              ],
            });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []); // Empty dependency array ensures the effect runs once on component mount
  return (
    <div className='col-flex-box col-8 shadow'>
      <h4>Users Distribution On Monthly Basis</h4>
        <Bar data={chartData} options={options}></Bar>
    </div>
  )
}

export default ByMonth