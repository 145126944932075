import React from 'react'
import './Users.css';
import './Reports.css'

import CompanyInfo from '../components/shopzetu/CompanyInfo'
import ByDate from '../components/shopzetu/ByDate'
import ByAge from '../components/shopzetu/ByAge'
import ByGender from '../components/shopzetu/ByGender'
import ByLocation from '../components/shopzetu/ByLocation'
import GrandTotalClicks from '../components/shopzetu/GrandTotalClicks'
import TotalClicks from '../components/shopzetu/TotalClicks'
import ClosedClicks from '../components/shopzetu/ClosedClicks';
import WrongAnswers from '../components/shopzetu/WrongAnswers';
import UniqueClicks from '../components/shopzetu/UniqueClicks';
import ImpressionsToday from '../components/shopzetu/ImpressionsToday';
import TotalImpressions from '../components/shopzetu/TotalImpressions';
import UniqueImpressions from '../components/shopzetu/UniqueImpressions';
import UniqueImpressionsToday from '../components/shopzetu/UniqueImpressionsToday';

import CompanyInfoT from '../components/shopzetut/CompanyInfoT'
import ByDateT from '../components/shopzetut/ByDateT'
import ByAgeT from '../components/shopzetut/ByAgeT'
import ByGenderT from '../components/shopzetut/ByGenderT'
import ByLocationT from '../components/shopzetut/ByLocationT'
import GrandTotalClicksT from '../components/shopzetut/GrandTotalClicksT'
import TotalClicksT from '../components/shopzetut/TotalClicksT'
import ClosedClicksT from '../components/shopzetut/ClosedClicksT';
import WrongAnswersT from '../components/shopzetut/WrongAnswersT';
import UniqueClicksT from '../components/shopzetut/UniqueClicksT';
import ImpressionsTodayT from '../components/shopzetut/ImpressionsTodayT';
import TotalImpressionsT from '../components/shopzetut/TotalImpressionsT';
import UniqueImpressionsT from '../components/shopzetut/UniqueImpressionsT';
import UniqueImpressionsTodayT from '../components/shopzetut/UniqueImpressionsTodayT';


import CompanyInfoA from '../components/shopzetua/CompanyInfoA'
import ByDateA from '../components/shopzetua/ByDateA'
import ByAgeA from '../components/shopzetua/ByAgeA'
import ByGenderA from '../components/shopzetua/ByGenderA'
import ByLocationA from '../components/shopzetua/ByLocationA'
import GrandTotalClicksA from '../components/shopzetua/GrandTotalClicksA'
import TotalClicksA from '../components/shopzetua/TotalClicksA'
import ClosedClicksA from '../components/shopzetua/ClosedClicksA';
import WrongAnswersA from '../components/shopzetua/WrongAnswersA';
import UniqueClicksA from '../components/shopzetua/UniqueClicksA';
import ImpressionsTodayA from '../components/shopzetua/ImpressionsTodayA';
import TotalImpressionsA from '../components/shopzetua/TotalImpressionsA';
import UniqueImpressionsA from '../components/shopzetua/UniqueImpressionsA';
import UniqueImpressionsTodayA from '../components/shopzetua/UniqueImpressionsTodayA';

function ShopZetu() {
  return (
    <div>
      <div className='reports bg1'>
      <CompanyInfo/>
        <div className='report-head'>
            <h4>ShopZetu Analytics : Ad ID : 68</h4>
        </div>
        <div className='card-container'>
          <GrandTotalClicks/>
          <TotalClicks/>
          <UniqueClicks/>
          <ClosedClicks/>
          <WrongAnswers/>
        </div>
        <div className='report-row'>
          <ByDate/>
          <ByGender/>
        </div>
        <div className='card-container'>
          <TotalImpressions/>
          <UniqueImpressions/>
          <ImpressionsToday/>
          <UniqueImpressionsToday/>
        </div>
          <ByAge/>
          <ByLocation/>
    </div>

    <div className='reports bg2'>
      <CompanyInfoT/>
        <div className='report-head'>
            <h4>ShopZetu Analytics : Ad ID : 49</h4>
        </div>
        <div className='card-container'>
          <GrandTotalClicksT/>
          <TotalClicksT/>
          <UniqueClicksT/>
          <ClosedClicksT/>
          <WrongAnswersT/>
        </div>
        <div className='report-row'>
          <ByDateT/>
          <ByGenderT/>
        </div>
        <div className='card-container'>
          <TotalImpressionsT/>
          <UniqueImpressionsT/>
          <ImpressionsTodayT/>
          <UniqueImpressionsTodayT/>
        </div>
          <ByAgeT/>
          <ByLocationT/>
    </div>

    <div className='reports bg1'>
      <CompanyInfoA/>
        <div className='report-head'>
            <h4>ShopZetu Analytics : Ad ID : 32</h4>
        </div>
        <div className='card-container'>
          <GrandTotalClicksA/>
          <TotalClicksA/>
          <UniqueClicksA/>
          <ClosedClicksA/>
          <WrongAnswersA/>
        </div>
        <div className='report-row'>
          <ByDateA/>
          <ByGenderA/>
        </div>
        <div className='card-container'>
          <TotalImpressionsA/>
          <UniqueImpressionsA/>
          <ImpressionsTodayA/>
          <UniqueImpressionsTodayA/>
        </div>
          <ByAgeA/>
          <ByLocationA/>
    </div>
    </div>
  )
}

export default ShopZetu