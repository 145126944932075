import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
const prod = 'https://dash-api.teleeza.africa'
//'http://localhost:5000'

function ByDay() {
    const [allDays, setAllDays] = useState([]);

    useEffect(() => {
        axios.get(prod + '/merchants/byday')
        .then((response) => {
          setAllDays(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
      }, []);
      
  return (
    <div className='days-cards'>
        {
          allDays.map((item) => (
            <div className='day-item' key={item.id} >
              <h5 >{item.DayOfWeek}  <span className='v-num'>{item.RedeemedVouchers}</span></h5>
            </div>
         )) 
        }
    </div>
  )
}

export default ByDay