import React from 'react'
import './News.css'
import Active from '../components/news/Active'
import ActiveManual from '../components/news/ActiveManual'
import Activeproviders from '../components/news/Activeproviders'
import AllProviders from '../components/news/AllProviders'
import Today from '../components/news/Today'
import Total from '../components/news/Total'
import ActivepublishersGraph from '../components/news/ActivepublishersGraph'
import Daily from '../components/news/Daily'

function News() {
  return (
    <div className='news-container'>
      <div className='news-cards-container'>
        <Total/>
        <Active/>
        <Today/>
        <ActiveManual/>
        <Activeproviders/>
        <AllProviders/>
      </div>
      <Daily/>
      <ActivepublishersGraph/>
    </div>
  )
}

export default News